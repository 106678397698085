import {
  ParticipantRole,
  TransactionContacts,
  TransactionParticipant,
  TeamContactInfo,
} from '@endpoint/endpoint-bff-graphql-schema';
import { User } from 'utils/context';

import { endpointTeamBanking, endpointTeamBankingUnity } from '../data/EndpointContactData';

type TransactionParticipantWithTitle = TransactionParticipant & { title: string };

export const sortUserTransactionGroup = (group: TransactionParticipantWithTitle[], userId: User['id']) => {
  const currentUser = group.find((participant) => participant.contactId === userId);

  if (!currentUser) {
    return group;
  }

  const groupWithoutUser = group.filter((participant) => participant.contactId !== userId);

  return [currentUser, ...groupWithoutUser];
};

export const formatParticipantName = ({
  firstName = '',
  middleName = '',
  lastName = '',
  entityName = '',
}: TransactionParticipant) => {
  return (firstName && lastName ? `${firstName || ''} ${middleName || ''} ${lastName || ''}` : `${entityName}`)
    .trim()
    .replace(/  +/g, ' ');
};

const getBuyerGroup = (contacts: TransactionContacts): TransactionParticipantWithTitle[] => {
  const buyersWithTitle = contacts.buyers.map((buyer) => ({
    ...buyer,
    title: buyer.roles.includes(ParticipantRole.BORROWER) ? 'Borrower' : 'Buyer',
  }));

  const buyerAgentsWithTitle = contacts.buyerAgents.map((buyerAgent) => {
    let title = 'Selling Agent';

    if (buyerAgent.roles.includes(ParticipantRole.SELLING_AGENT_TRANSACTION_COORDINATOR)) {
      title = 'Transaction Coordinator';
    }

    // @ts-ignore
    if (buyerAgent.roles.includes(ParticipantRole.SELLING_BROKER)) {
      title = 'Selling Broker';
    }

    return { ...buyerAgent, title };
  });

  return [...buyersWithTitle, ...buyerAgentsWithTitle];
};

const getSellerGroup = (contacts: TransactionContacts): TransactionParticipantWithTitle[] => {
  const sellersWithTitle = contacts.sellers.map((seller) => ({
    ...seller,
    title: seller.roles.includes(ParticipantRole.BORROWER) ? 'Borrower' : 'Seller',
  }));

  const sellerAgentsWithTitle = contacts.sellerAgents.map((sellerAgent) => {
    let title = 'Listing Agent';

    if (sellerAgent.roles.includes(ParticipantRole.LISTING_AGENT_TRANSACTION_COORDINATOR)) {
      title = 'Transaction Coordinator';
    }

    if (sellerAgent.roles.includes(ParticipantRole.LOAN_OFFICER)) {
      title = 'Loan Officer';
    }

    if (sellerAgent.roles.includes(ParticipantRole.LOAN_PROCESSOR)) {
      title = 'Loan Processor';
    }

    if (sellerAgent.roles.includes(ParticipantRole.MORTGAGE_BROKER)) {
      title = 'Mortgage Broker';
    }

    return { ...sellerAgent, title };
  });

  return [...sellersWithTitle, ...sellerAgentsWithTitle];
};

const formatGroupWithUser = (group: TransactionParticipantWithTitle[], userId: User['id']) => {
  const sortedGroup = sortUserTransactionGroup(group, userId);

  return sortedGroup.map((participant) => {
    const name = formatParticipantName(participant);
    const isCurrentUser = participant.contactId === userId;

    return {
      participantId: participant.id,
      participantName: name && isCurrentUser ? `${name} (You)` : name,
      participantTitle: participant.title,
    };
  });
};

const formatGroupWithoutUser = (group: TransactionParticipantWithTitle[]) => {
  return group.map((participant) => ({
    participantId: participant.id,
    participantName: formatParticipantName(participant),
    participantTitle: participant.title,
  }));
};

export const isUserInBuyerGroup = (contacts: TransactionContacts, userId: User['id']) =>
  [...contacts.buyers, ...contacts.buyerAgents].findIndex((participant) => participant.contactId === userId) > -1;

export const getTransactionGroupsForDisplay = (contacts: TransactionContacts, userId: User['id']) => {
  const buyerGroup = getBuyerGroup(contacts);
  const sellerGroup = getSellerGroup(contacts);

  if (isUserInBuyerGroup(contacts, userId)) {
    return {
      groupWithUser: formatGroupWithUser(buyerGroup, userId),
      groupWithoutUser: formatGroupWithoutUser(sellerGroup),
    };
  }

  return {
    groupWithUser: formatGroupWithUser(sellerGroup, userId),
    groupWithoutUser: formatGroupWithoutUser(buyerGroup),
  };
};

export const getContactBankingInfoByMarketId = (marketId?: number | null, isUnityTransaction?: boolean) => {
  if (!marketId) {
    return undefined;
  }

  return (isUnityTransaction ? endpointTeamBankingUnity : endpointTeamBanking).find((teamContact) =>
    teamContact.marketId.includes(marketId),
  );
};

export const getDisplayGroupOrder = (contacts: Array<any>) => {
  const agent = contacts.filter((contact) => contact.participantTitle.toLowerCase().includes('agent'));

  const seller = contacts.filter((contact) => contact.participantTitle.toLowerCase().includes('seller'));

  const buyer = contacts.filter((contact) => contact.participantTitle.toLowerCase().includes('buyer'));

  const tc = contacts.filter((contact) => contact.participantTitle.toLowerCase().includes('transaction coordinator'));

  return [...agent, ...seller, ...buyer, ...tc];
};

export const getSuiteFromAddress = (street: string | undefined) => {
  if (!street) return {};
  const [address, suite] = street.split(',');

  const isNewTexasOfficeSuite = suite.includes('G300');

  if (isNewTexasOfficeSuite) {
    return {
      address,
      suite: 'Suite #G300',
    };
  }

  return {
    address,
    suite: suite ? `Suite #${suite.replace(/[^0-9-]/g, '')}` : null,
  };
};

export const formatTeamContactInfo = (teamContactInfo: TeamContactInfo | undefined) => {
  return {
    ...teamContactInfo,
    phone: teamContactInfo?.phone && `+1 ${teamContactInfo?.phone}`,
    cityStateZip: `${teamContactInfo?.city ? `${teamContactInfo?.city},` : ''}${
      teamContactInfo?.state && ` ${teamContactInfo?.state}`
    }${teamContactInfo?.zip && ` ${teamContactInfo?.zip}`}`.trim(),
  };
};

export enum LegacyRoleType {
  AGENT_READ = 'read:agent',
  AGENT_WRITE = 'write:agent',
  BORROWER_READ = 'read:borrower',
  BORROWER_WRITE = 'write:borrower',
  BUYER_READ = 'read:buyer',
  BUYER_WRITE = 'write:buyer',
  SELLER_READ = 'read:seller',
  SELLER_WRITE = 'write:seller',
}

export enum TransactionType {
  REFINANCE = 'Refinance',
}

export enum OfficeName {
  AZ = 'Arizona',
  CA_NORTH = 'California - North',
  CA_SOUTH_UTC = 'California - SoCal UTC',
  CA_SOUTH_LEGACY = 'California - South',
  CO = 'Colorado',
  FL = 'Florida',
  TN = 'Tennessee',
  TX = 'Texas',
  WA = 'Washington',
  MO = 'Missouri',
  OH = 'Ohio',
  IN = 'Indiana',
  KS = 'Kansas',
}

export enum TodoStepId {
  ABOUT = 'about',
  SUMMARY = 'summary',
  THANK_YOU = 'thank-you',
  PAYMENT_METHOD_OPTIONS = 'payment-method-options',
  PAY_ONLINE = 'pay_online',
}

export enum QueryParam {
  EDIT_STEP = 'edit-step',
  DISABLED_PAY_ONLINE = 'disabled-pay-online',
  PAYMENT_METHOD_OPTIONS = 'payment-method-options',
}

export enum EmdPaymentOption {
  PAY_ONLINE = 'Pay Online',
}

import React, { FC, useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import { FormikPasswordInputGroup } from 'components/form/FormikBlockparty';
import { Box, Heading, Stack, Text, Button, Flex } from '@endpoint/blockparty';
import * as yup from 'yup';
import { useResetPassword } from 'hooks/auth/useResetPassword';
import { useResetPasswordExpired } from 'hooks/auth/useResetPasswordExpired';

import { AppContext, AppContextType } from '../../../utils/context';
import ResetSuccess from '../ResetSuccess';
import { PasswordCriteria } from '../PasswordCriteria';
import { PASSWORD_REGEX, PASSWORD_COMPLEXITY_ERROR } from '../../Utilities/Constant';
import Alerts from '../Alerts';

interface IResetPasswordFormValues {
  password: string;
  confirmedPassword: string;
}

export const resetPasswordValidationSchema = yup.object().shape({
  password: yup.string().required('Password is required').matches(PASSWORD_REGEX, PASSWORD_COMPLEXITY_ERROR),
  confirmedPassword: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ResetPassword: FC = () => {
  const { authFields }: AppContextType = useContext(AppContext);
  const [localPassword, setLocalPassword] = useState('');
  const resetPassword = useResetPassword();
  const resetPasswordExpired = useResetPasswordExpired();

  const handleResetPassword = async (values: IResetPasswordFormValues) => {
    if (authFields.passwordExpired) {
      void resetPasswordExpired(values.password);
    } else {
      void resetPassword(values.password);
    }
  };

  if (!authFields.resetPassword) {
    return <ResetSuccess />;
  }

  return (
    <>
      <Box mb="space70" textAlign="center">
        <Heading as="h2" mb="space50" size="fontSize60">
          Reset your password
        </Heading>

        <Text>
          {authFields.passwordExpired && 'Your password has expired and will need to be reset in order to sign in. '}For
          your security, we suggest creating a new password that you haven’t used before.
        </Text>
      </Box>
      <Alerts />
      <Stack spacing="space60">
        <Formik
          initialValues={{ password: '', confirmedPassword: '' }}
          validationSchema={resetPasswordValidationSchema}
          onSubmit={handleResetPassword}
        >
          {({ isValid, dirty }) => (
            <Form>
              <FormikPasswordInputGroup
                autoFocus
                data-test-id="password-input"
                label="Create a new password"
                name="password"
                placeholder="Create a new password"
                onChange={(e: React.ChangeEvent<any>) => setLocalPassword(e.target.value)}
              />
              <Stack mb="space50" mt="space50" spacing="space20">
                <PasswordCriteria password={localPassword} />
              </Stack>
              <FormikPasswordInputGroup
                data-test-id="confirmed-password-input"
                label="Confirm password"
                name="confirmedPassword"
                placeholder="Confirm password"
              />
              <Flex justifyContent="flex-end" mt="space80">
                <Button isDisabled={!(isValid && dirty)} type="submit">
                  Reset Password
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Stack>
    </>
  );
};

export default ResetPassword;

import { TransactionSummaryParticipant } from '@endpoint/endpoint-bff-graphql-schema';

import { TransactionSummaryCardDetails } from '..';

export const getTransactionRole = ({ transaction, userId }: TransactionSummaryCardDetails) => {
  const { participants } = transaction;

  const matchedUser = participants.find((participant) => participant.contactId === userId);

  const matchedUserRole = matchedUser?.roles[0];

  let transactionUserRole = 'Buyer:';
  let transactionUserName = 'Not available yet';

  if (matchedUserRole) {
    transactionUserRole = getUserRole(matchedUserRole);
    transactionUserName = getUserName(participants, matchedUserRole);
  }

  return {
    label: transactionUserRole,
    value: transactionUserName,
  };
};

const roleMapping: Record<string, string> = {
  BUYER: 'Agent:',
  SELLER: 'Agent:',
  LISTING_AGENT_TRANSACTION_COORDINATOR: 'Agent:',
  SELLING_AGENT_TRANSACTION_COORDINATOR: 'Agent:',
  SELLING_AGENT: 'Buyer:',
  LISTING_AGENT: 'Seller:',
  BORROWER: 'M. Broker:',
  LOAN_OFFICER: 'Borrower:',
  MORTGAGE_BROKER: 'Borrower:',
  LOAN_PROCESSOR: '',
  ESCROW_AGENT: '',
  PROPTECH_PARTNER: '',
  PROPTECH_BUYER: '',
  PROPTECH_SELLER: '',
  BUYER_API: '',
  SELLER_API: '',
  SETTLEMENT_COMPANY: '',
  CLOSING_SPECIALIST: '',
};

export const getUserRole = (role: string) => {
  return roleMapping[role] || '';
};

const getUserName = (participants: TransactionSummaryParticipant[], role: string) => {
  switch (role) {
    case 'BUYER':
    case 'SELLING_AGENT_TRANSACTION_COORDINATOR':
      return findRespectiveParticipants(participants, 'SELLING_AGENT');
    case 'SELLER':
    case 'LISTING_AGENT_TRANSACTION_COORDINATOR':
      return findRespectiveParticipants(participants, 'LISTING_AGENT');
    case 'SELLING_AGENT':
      return findRespectiveParticipants(participants, 'BUYER');
    case 'LISTING_AGENT':
      return findRespectiveParticipants(participants, 'SELLER');
    case 'BORROWER':
      return findRespectiveParticipants(participants, 'MORTGAGE_BROKER');
    case 'LOAN_OFFICER':
    case 'MORTGAGE_BROKER':
      return findRespectiveParticipants(participants, 'BORROWER');
    default:
      return '';
  }
};

export const findRespectiveParticipants = (participants: TransactionSummaryParticipant[], expectedRole: string) => {
  const foundParticipants = participants.filter((participant) => participant?.roles?.includes(expectedRole));

  const participantNames = foundParticipants.length
    ? foundParticipants
        .map((participant) => {
          const { firstName, lastName } = participant || {};

          return firstName && lastName ? `${firstName} ${lastName}` : 'Name not available';
        })
        .join(', ')
    : 'Not available yet';

  return participantNames;
};

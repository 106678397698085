import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { localStorageRemoveItem, localStorageSetItem } from 'utils/localStorageAccessor';

import { CustomStorage, CUSTOM_STORAGE_VAULT } from './customStorage';

const CUSTOM_ORG_ID_ATTRIBUTE = 'custom:OrgId';

export const ACCESS_TOKEN_KEY = 'ep_access_token';
export const ID_TOKEN_KEY = 'ep_id_token';

export const SSO_USER_INFO_KEY = 'SSO_USER_INFO';

enum WindowListeningEvent {
  REQUESTING_SHARED_CREDENTIALS = 'REQUESTING_SHARED_CREDENTIALS',
  CREDENTIALS_SHARING = 'CREDENTIALS_SHARING',
  FINISHED_SHARING_CREDENTIALS = 'FINISHED_SHARING_CREDENTIALS',
  CLEAR_CREDENTIALS = 'CLEAR_CREDENTIALS',
}

const sendWindowEvents = (key: string, value: string) => {
  localStorageSetItem(key, value);
  localStorageRemoveItem(key);
};

const getUserSession = () => Auth.currentSession();

const setupMFAForNewUser = async (user: any) => {
  try {
    await Auth.setPreferredMFA(user, 'SMS');
  } catch (err: any) {
    const email = user?.attributes?.email ?? '';

    Sentry.captureEvent({
      level: Sentry.Severity.Error,
      message: `Unable to update MFA for user: ${email} Err: ${JSON.stringify(err)}}`,
    });
  }
};

const getAccessToken = async () => {
  const userSession = await getUserSession();

  return userSession.getIdToken().getJwtToken();
};

const getRefreshToken = async () => {
  const userSession = await getUserSession();

  return userSession.getRefreshToken().getToken();
};

const renewToken = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const session = await Auth.currentSession();

  // @ts-ignore [CognitoUser type is missing the refreshSession, open issue on AWS]
  await user.refreshSession(session.refreshToken, () => {});

  return getAccessToken();
};

const clearTokens = async () => {
  sendWindowEvents(WindowListeningEvent.CLEAR_CREDENTIALS, Date.now().toString());

  clearTokensV2();
};

// utils for isMTEPSignInSignUpEnabled flow

const getAccessTokenV2 = async () => {
  return CustomStorage.getItem(ACCESS_TOKEN_KEY);
};

const clearTokensV2 = async () => {
  CustomStorage.removeItem(ACCESS_TOKEN_KEY);
  CustomStorage.removeItem(ID_TOKEN_KEY);
};

export {
  clearTokens,
  CustomStorage,
  CUSTOM_ORG_ID_ATTRIBUTE,
  CUSTOM_STORAGE_VAULT,
  getAccessToken,
  getRefreshToken,
  renewToken,
  sendWindowEvents,
  setupMFAForNewUser,
  WindowListeningEvent,
  getAccessTokenV2,
  clearTokensV2,
};

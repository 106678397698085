import { formatCurrency } from 'utils/formatCurrency';
import { TransactionStatus, TransactionSummary } from '@endpoint/endpoint-bff-graphql-schema';

import {
  DetailList,
  TransactionSummaryCardDetails,
  displayDateStatus,
  getListingDetails,
  getSigningDetails,
  getTransactionRole,
  getUserRoles,
} from '..';

export const getSaleDetails = ({ transaction, userId }: TransactionSummaryCardDetails): DetailList[] => {
  const { status, participants } = transaction;

  switch (status) {
    case TransactionStatus.PRELIM: {
      if (userId) {
        const userRole = getUserRoles(participants, userId);
        const isTransactionCoordinator =
          userRole.includes('LISTING_AGENT_TRANSACTION_COORDINATOR') ||
          userRole.includes('SELLING_AGENT_TRANSACTION_COORDINATOR');

        if (isTransactionCoordinator) {
          return [getTransactionRole({ transaction, userId }), ...getListingDetails(transaction)];
        }
      }

      return [...getListingDetails(transaction)];
    }

    case TransactionStatus.IN_ESCROW:
      return [getTransactionRole({ transaction, userId }), ...baseSaleDetails(transaction)];

    default:
      return [getTransactionRole({ transaction, userId }), ...baseSaleDetails(transaction)];
  }
};

const baseSaleDetails = (transaction: TransactionSummary): DetailList[] => {
  const { fileNum, price } = transaction;

  return [
    {
      label: 'File #',
      value: fileNum || 'Not available yet',
    },
    {
      label: 'Price:',
      value: price ? formatCurrency(price) : 'Not available yet',
    },
    { ...getSigningDetails(transaction) },
    { ...displayDateStatus(transaction) },
  ];
};

import React, { FC, useCallback } from 'react';
import {
  Avatar,
  Box,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
  useDropdownMenuState,
} from '@endpoint/blockparty';
import { useNavigate } from 'react-router-dom';
import { useAppContext, useAppConfigsContext } from 'utils/context';
import { trackAction } from 'utils/analytics';
import { ProfileMenuTrackingEvents } from 'consts/analytics';
import { useSignOut } from 'hooks/auth/useSignOut';
import { useRevokeSSO } from 'hooks/auth/SSO/useRevokeSSO';
import { localStorageGetItem, localStorageRemoveItem } from 'utils/localStorageAccessor';
import { SSO_USER_INFO_KEY } from 'utils/auth/storage';

import { getHeaderRightRoutes, RouteLabels } from '../routes';

export const formatUserInitials = (firstName?: string, lastName?: string) => {
  return firstName && lastName ? `${firstName[0]} ${lastName[0]}` : null;
};

export const HeaderAvatar: FC = () => {
  const { PRIVACY_URL, HELP_URL } = useAppConfigsContext();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const revokeSSO = useRevokeSSO();
  const avatarNavMenu = useDropdownMenuState({ placement: 'bottom-end', gutter: 12 });
  const { user } = useAppContext();
  const formattedUserInitials = formatUserInitials(user?.firstName, user?.lastName);

  const onAvatarClick = useCallback(() => {
    trackAction(ProfileMenuTrackingEvents.PROFILE_ICON_CLICKED);
  }, []);

  return (
    <Box>
      <DropdownMenuTrigger state={avatarNavMenu} onClick={onAvatarClick}>
        <Avatar
          border="1px solid"
          borderColor="background"
          cursor="pointer"
          data-test-id="user-avatar"
          name={formattedUserInitials}
          size={32}
          src={user?.photo}
        />
      </DropdownMenuTrigger>
      <DropdownMenu aria-label="Main menu" minWidth={192} state={avatarNavMenu}>
        {getHeaderRightRoutes(PRIVACY_URL, HELP_URL).map(({ route, label, callback }) => {
          return (
            <DropdownMenuItem
              key={`nav-bar-avatar-menu-item-${label}`}
              data-test-id={`nav-bar-avatar-menu-item-${label}`}
              p="space50"
              state={avatarNavMenu}
              onClick={async () => {
                avatarNavMenu.hide();
                trackAction(`${label} Clicked`);

                if (label === RouteLabels.SignOut) {
                  const ssoUserInfo = JSON.parse(localStorageGetItem(SSO_USER_INFO_KEY) ?? '{}');

                  if (ssoUserInfo.isSSO) {
                    await revokeSSO();
                    localStorageRemoveItem(SSO_USER_INFO_KEY);
                  } else {
                    await signOut();
                  }
                }

                callback && callback();
                route && navigate(route);
              }}
            >
              {label}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenu>
    </Box>
  );
};

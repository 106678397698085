import React, { FC, useContext, useEffect } from 'react';
import { Routes, Route, useLocation, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { AppContext, AppContextType } from 'utils/context';
import { EMPTY_STRING, RouteOptions } from 'Auth/Utilities/Constant';
import { Loading } from '@endpoint/blockparty';
import { TRANSACTION_IN_ESCROW } from 'consts/routes';
import AccountLocked from 'Auth/Components/AccountLocked';
import ForgotCredentials from 'Auth/Components/ForgotCredentials';
import ForgotEmail from 'Auth/Components/ForgotEmail';
import ForgotPassword from 'Auth/Components/ForgotPassword';
import Mfa from 'Auth/Components/Mfa';
import UnverifiedUser from 'Auth/Components/UnverifiedUser';
import ResetPassword from 'Auth/Components/ResetPassword';
import SignIn from 'Auth/Components/Signin';
import SignInContainer from 'Auth/Containers/SignIn';
import SignUp from 'Auth/Components/Signup';
import SignUpContainer from 'Auth/Containers/SignUp';
import MfaSignUp from 'Auth/Components/MfaSignUp';
import SSOCallbackHandler from 'Auth/Components/SSOCallbackHandler';
import PageTracker from 'utils/analytics/pageTracker';
import ResetSuccessMfa from 'Auth/Components/ResetSuccessMfa';

const UnAuthorizedApp: FC = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('referrer');

  const navigate = useNavigate();
  const { authFields, setAuthFields, user }: AppContextType = useContext(AppContext);

  useEffect(() => {
    // move outside of the hook when feature is permanently turned on
    const publicRoutes = [RouteOptions.SIGN_IN, RouteOptions.SIGN_UP];
    const isPublicRoute = (path: string): boolean =>
      publicRoutes.some((item: string): boolean => path.startsWith(item));
    const authFieldsUpdate = { ...authFields };
    let shouldUpdateAuthFields = false;
    let navigateTo = '';
    let shouldNavigate = false;

    if (referrer && referrer !== authFields.referrer) {
      authFieldsUpdate.referrer = referrer;
      shouldUpdateAuthFields = true;
    }

    if (authFields.navigateToAfterLogin === EMPTY_STRING && isPublicRoute(pathname)) {
      authFieldsUpdate.navigateToAfterLogin = TRANSACTION_IN_ESCROW;
      shouldUpdateAuthFields = true;
    } else if (authFields.navigateToAfterLogin === EMPTY_STRING) {
      authFieldsUpdate.navigateToAfterLogin = pathname;
      shouldUpdateAuthFields = true;
      navigateTo = RouteOptions.SIGN_IN;
      shouldNavigate = true;
    }

    if (shouldUpdateAuthFields) setAuthFields(authFieldsUpdate);
    if (shouldNavigate) navigate(navigateTo);
  }, [authFields, navigate, pathname, setAuthFields, referrer]);

  return (
    <>
      <PageTracker />
      {!!user.loading && <Loading left="49%" position="absolute" size="medium" top="40%" zIndex={1} />}
      <Routes>
        <Route element={<SSOCallbackHandler />} path={RouteOptions.ROOT} />
        <Route element={<SignInContainer />} path={RouteOptions.SIGN_IN}>
          <Route element={<SignIn />} index />
          <Route element={<Mfa />} path={RouteOptions.REQUEST_MFA} />
          <Route element={<ResetSuccessMfa />} path={RouteOptions.RESET_SUCCESS_MFA} />
          <Route element={<ForgotCredentials />} path={RouteOptions.FORGOT_CREDENTIALS} />
          <Route element={<ForgotEmail />} path={RouteOptions.FORGOT_EMAIL} />
          <Route element={<ForgotPassword />} path={RouteOptions.FORGOT_PASSWORD} />
          <Route element={<ResetPassword />} path={RouteOptions.RESET_PASSWORD} />
          <Route element={<AccountLocked />} path={RouteOptions.ACCOUNT_LOCKED} />
          <Route element={<UnverifiedUser />} path={RouteOptions.UNVERIFIED_USER} />
          <Route element={<SignIn />} path="*" />
        </Route>
        <Route element={<SignUpContainer />} path={RouteOptions.SIGN_UP}>
          <Route element={<SignUp />} index />
          <Route element={<MfaSignUp />} path={RouteOptions.REQUEST_MFA} />
          <Route element={<AccountLocked />} path={RouteOptions.ACCOUNT_LOCKED} />
          <Route element={<SignUp />} path="*" />
        </Route>
      </Routes>
    </>
  );
};

export default UnAuthorizedApp;

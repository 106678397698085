import React, { useEffect, useRef, useState, useCallback } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { Loading, useTheme } from '@endpoint/blockparty';
import {
  AuthFields,
  DEFAULT_AUTH_STATE,
  DEFAULT_USER_STATE,
  User,
  AppContext,
  AppContextType,
  TodoData,
  IsOnline,
  DEFAULT_TODO_STATE,
  DEFAULT_IS_ONLINE_STATE,
  useAppConfigsContext,
} from 'utils/context';
import { ApolloProvider } from '@apollo/client';
import { clearTokensV2, CUSTOM_STORAGE_VAULT, sendWindowEvents, WindowListeningEvent } from 'utils/auth/storage';
import { client } from 'Apollo';
import AuthorizedApp from 'App/AuthorizedApp';
import UnAuthorizedApp from 'App/UnAuthorizedApp';
import { AuthContextProvider, useAuth } from 'hooks/auth/useAuth';
import * as Sentry from '@sentry/react';
import { localStorageGetItem } from 'utils/localStorageAccessor';
import { redirectNotificationLinks } from 'utils/redirectNotificationLinks';
import { getGlobalConfig } from 'utils/config';
import { TRANSACTION_IN_ESCROW } from 'consts/routes';
import _ from 'lodash';
import TagManager from 'react-gtm-module';

import packageInfo from '../package.json';
import { HeadTags } from './components/HeadTags';

function AppWithContext() {
  const { isLoggedIn } = useAuth();
  const globalConfig = getGlobalConfig();

  const [isAuthenticatedUser, setIsAuthenticatedUser] = useState<boolean | null>(null);
  const [authFields, setAuthFields] = useState<AuthFields>(DEFAULT_AUTH_STATE);
  const [user, setUser] = useState<User>(DEFAULT_USER_STATE);
  const [error, setError] = useState<any>(null);
  const [todoData, setTodoData] = useState<TodoData>(DEFAULT_TODO_STATE);
  const [isOnline, setIsOnline] = useState<IsOnline>(DEFAULT_IS_ONLINE_STATE);
  const { GOOGLE_TAG_MANAGER } = useAppConfigsContext();

  useEffect(() => {
    if (GOOGLE_TAG_MANAGER?.gtmId) {
      TagManager.initialize(GOOGLE_TAG_MANAGER);
    }
  }, [GOOGLE_TAG_MANAGER]);

  const appContextValue: AppContextType = {
    authFields,
    setAuthFields,
    user,
    setUser,
    error,
    setError,
    todoData,
    setTodoData,
    isOnline,
  };

  useEffect(() => {
    setIsAuthenticatedUser(isLoggedIn);
  }, [isLoggedIn]);

  useEffect(() => {
    // set redirect url if user is authenticated via oauth flow
    const searchParams = new URLSearchParams(window.location.search);
    const paramsDict = _.fromPairs(Array.from(searchParams.entries()));

    if (paramsDict.code) {
      setAuthFields({
        ...authFields,
        navigateToAfterLogin: TRANSACTION_IN_ESCROW,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserDetails = useCallback(() => {
    if (isAuthenticatedUser === false) {
      setIsAuthenticatedUser(null);
    }
  }, [isAuthenticatedUser]);

  const setIsOnlineTrue = () => setIsOnline(true);
  const setIsOnlineFalse = () => setIsOnline(false);

  const addNetworkListeners = () => {
    window.addEventListener('online', setIsOnlineTrue);
    window.addEventListener('offline', setIsOnlineFalse);
  };

  const removeNetworkListeners = () => {
    window.removeEventListener('online', setIsOnlineTrue);
    window.removeEventListener('offline', setIsOnlineFalse);
  };

  if (globalConfig.DATADOG_RUM_APP_ID && globalConfig.DATADOG_RUM_CLIENT_TOKEN) {
    datadogRum.init({
      applicationId: globalConfig.DATADOG_RUM_APP_ID,
      clientToken: globalConfig.DATADOG_RUM_CLIENT_TOKEN,
      env: globalConfig.ENVIRONMENT,
      service: packageInfo.name,
      version: packageInfo.version,
      sampleRate: globalConfig.DATADOG_RUM_SAMPLE_RATE,
      premiumSampleRate: globalConfig.DATADOG_RUM_PREMIUM_SAMPLE_RATE,
      site: 'datadoghq.com',
      trackInteractions: true,
      silentMultipleInit: true,
    });

    datadogRum.startSessionReplayRecording();
  }

  const getAppComponent = useCallback(() => {
    let appComponent = <Loading left="49%" position="absolute" size="medium" top="49%" zIndex={1} />;

    if (isAuthenticatedUser === true) {
      appComponent = <AuthorizedApp />;
    } else if (isAuthenticatedUser === false) {
      appComponent = <UnAuthorizedApp />;
    }

    return appComponent;
  }, [isAuthenticatedUser]);

  const initializeAppRef = useRef<Function>();

  const initializeApp = () => {
    const vault = localStorageGetItem(CUSTOM_STORAGE_VAULT);

    if (vault !== 'true') {
      window.addEventListener('storage', (event) => {
        switch (event.key) {
          case WindowListeningEvent.REQUESTING_SHARED_CREDENTIALS:
            Object.keys(sessionStorage).forEach((sessionkey: string) => {
              const value = sessionStorage.getItem(sessionkey);
              const pair = { key: sessionkey, value };

              sendWindowEvents(WindowListeningEvent.CREDENTIALS_SHARING, JSON.stringify(pair));
            });

            sendWindowEvents(WindowListeningEvent.FINISHED_SHARING_CREDENTIALS, Date.now().toString());
            break;

          case WindowListeningEvent.CREDENTIALS_SHARING:
            if (event.newValue !== null) {
              const pair = JSON.parse(event.newValue);

              window.sessionStorage.setItem(pair.key, pair.value);
            }

            break;

          case WindowListeningEvent.FINISHED_SHARING_CREDENTIALS:
            if (event.newValue !== null) {
              getUserDetails();
            }

            break;
          case WindowListeningEvent.CLEAR_CREDENTIALS:
            clearTokensV2();

            getUserDetails();
            break;
          default:
        }
      });

      sendWindowEvents(WindowListeningEvent.REQUESTING_SHARED_CREDENTIALS, Date.now().toString());
    } else {
      window.addEventListener('storage', (event) => {
        switch (event.key) {
          case WindowListeningEvent.CLEAR_CREDENTIALS:
            clearTokensV2();

            getUserDetails();
            break;
          default:
        }
      });
    }

    getUserDetails();
    addNetworkListeners();

    return removeNetworkListeners;
  };

  initializeAppRef.current = initializeApp;

  useEffect(() => {
    if (initializeAppRef.current) {
      return initializeAppRef.current();
    }

    return () => {};
  }, []);

  redirectNotificationLinks();

  return (
    <AppContext.Provider value={appContextValue}>
      <ApolloProvider client={client}>{getAppComponent()}</ApolloProvider>
    </AppContext.Provider>
  );
}

const App = () => {
  const { config } = useTheme();

  return (
    <>
      <HeadTags config={config} />
      <AuthContextProvider>
        <AppWithContext />
      </AuthContextProvider>
    </>
  );
};

export default Sentry.withProfiler(App);

import { ACCESS_TOKEN_KEY, ID_TOKEN_KEY, CustomStorage } from 'utils/auth/storage';

import { getOrganizationId } from '../getOrganizationData';
import { getGlobalConfig } from '../config';

const { BFF_URL } = getGlobalConfig();

export const query = `
  mutation RefreshToken($input: RefreshTokenInput!){
    refreshToken(input: $input) {
      authentication {
        idToken,
        accessToken,
      }
    }
  }
`;

export default async function refreshTokens() {
  let accessToken;
  let idToken;
  const currentAccessToken = CustomStorage.getItem(ACCESS_TOKEN_KEY);
  const currentIdToken = CustomStorage.getItem(ID_TOKEN_KEY);

  if (!currentAccessToken) {
    return {};
  }

  const organizationId = await getOrganizationId();

  const variables = {
    input: {
      organizationId,
    },
  };

  try {
    const response = await fetch(BFF_URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        CurrentAccessToken: currentAccessToken,
        CurrentIdToken: currentIdToken,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });
    const responseJson = await response.json();

    if (responseJson?.data?.refreshToken?.authentication) {
      ({ accessToken, idToken } = responseJson.data.refreshToken.authentication);
    }
  } finally {
    if (accessToken && idToken) {
      CustomStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      CustomStorage.setItem(ID_TOKEN_KEY, idToken);
    } else {
      CustomStorage.removeItem(ACCESS_TOKEN_KEY);
      CustomStorage.removeItem(ID_TOKEN_KEY);
      window.location.assign('/signin');
    }
  }

  return accessToken;
}

import React, { FC, useState, useContext, useEffect, useCallback } from 'react';
import { Heading } from '@endpoint/blockparty';
import { useQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { CurrentStepComponentProps } from 'components/form/TwoColumnWithProgressContainer';
import { NavigationButton } from 'components/form/NavigationButton';
import { AlertMessage } from 'components/AlertMessage';
import { FormikSummaryDisplay } from 'components/form/FormikBlockparty';
import { TransactionsPayload, GET_TRANSACTIONS } from 'routes/Transactions/queries';
import { TransactionStatus } from '@endpoint/platform-api-connector/dist/graphql-types';
import { trackAction } from 'utils/analytics';
import { TransactionTrackingEvents } from 'consts/analytics';
import {
  OpenEscrowInput,
  OpenEscrowFromPrelimInput,
  TransactionSide,
  EscrowAssistant,
  UserInput,
} from '@endpoint/endpoint-bff-graphql-schema';
import { useOpenEscrow } from 'hooks/useOpenEscrow';
import { AppContext } from 'utils/context';
import { ProcessVariation } from '@endpoint/common-model';

import { FormikValue } from '../..';
import { OpenEscrowContextValue, OpenEscrowContext } from '../../Context';
import { Steps } from '..';
import { removeEmptyEscrowParticipants, removeStep3 } from '../../Helpers';

export const ReviewStep: FC<CurrentStepComponentProps<FormikValue>> = ({
  currentStepPosition = 7,
  setCurrentStepPosition,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { isFromPrelim }: OpenEscrowContextValue = useContext(OpenEscrowContext);
  const { user: userContext } = useContext(AppContext);
  const { values } = useFormikContext<FormikValue>();
  const { refetch: refetchInEscrow } = useQuery<TransactionsPayload>(GET_TRANSACTIONS, {
    variables: { where: { transactionStatus: TransactionStatus.IN_ESCROW } },
  });
  const { refetch: refetchPrelisting } = useQuery<TransactionsPayload>(GET_TRANSACTIONS, {
    variables: { where: { transactionStatus: TransactionStatus.PRELISTING } },
  });

  const {
    openEscrow,
    openEscrowResult,
    openEscrowError,
    openEscrowFromPrelim,
    openEscrowFromPrelimResult,
    openEscrowFromPrelimError,
  } = useOpenEscrow();

  const handleRefetch = useCallback(async () => {
    return Promise.all([refetchPrelisting(), refetchInEscrow()]).then(() => {
      setCurrentStepPosition(currentStepPosition + 1);
      setIsSubmitting(false);
    });
  }, [currentStepPosition, refetchInEscrow, refetchPrelisting, setCurrentStepPosition]);

  useEffect(() => {
    if (openEscrowResult || openEscrowFromPrelimResult) {
      setCurrentStepPosition(currentStepPosition + 1);
      setIsSubmitting(false);
    }

    if (openEscrowError || openEscrowFromPrelimError) {
      setHasError(true);
      setIsSubmitting(false);
    }
  }, [
    openEscrowResult,
    openEscrowFromPrelimResult,
    openEscrowError,
    openEscrowFromPrelimError,
    currentStepPosition,
    handleRefetch,
    setCurrentStepPosition,
    setIsSubmitting,
  ]);

  const mapTransactionSide = (representing: string) => {
    return representing === 'buyer' ? TransactionSide.BUYER : TransactionSide.SELLER;
  };

  const handleOpenOrderBFF = async () => {
    trackAction(TransactionTrackingEvents.OPEN_ESCROW_SUBMITTED);

    const transactionSide = mapTransactionSide(values.representing);

    setIsSubmitting(true);

    const openFromPrelim = isFromPrelim && values.prelimTransactionId && values.prelimTransactionId !== '';

    try {
      const userInput: UserInput = {
        contactType: userContext.contactType,
        isAgent: userContext.isAgent,
        isTransactionCoordinator: userContext.isTransactionCoordinator,
      };

      if (openFromPrelim) {
        const input: OpenEscrowFromPrelimInput = {
          transactionId: values.prelimTransactionId as string,
          documentIds: Object.values(values.file).map((file) => file.id),
          transactionSide,
          assistants: removeEmptyEscrowParticipants(values.teammates) as EscrowAssistant[],
          clients: removeEmptyEscrowParticipants(values.clients),
          notes: values.optionalNotes,
          processVariation: ProcessVariation.Unity,
          user: userInput,
        };

        await openEscrowFromPrelim(input);
      } else {
        const input: OpenEscrowInput = {
          documentIds: Object.values(values.file).map((file) => file.id),
          transactionSide,
          assistants: removeEmptyEscrowParticipants(values.teammates) as EscrowAssistant[],
          clients: removeEmptyEscrowParticipants(values.clients),
          notes: values.optionalNotes,
          processVariation: ProcessVariation.Unity,
          user: userInput,
        };

        await openEscrow(input);
      }
    } catch (error) {
      setHasError(true);
      setIsSubmitting(false);
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const FormatSummary = () => {
    const steps = isFromPrelim ? removeStep3(Steps) : Steps;
    const summarySteps = steps
      .map(({ summaryTitle, summary }, index) => ({
        summaryTitle,
        summary: summary ? summary(values) : 'N/A', // invoke summary function here
        index,
      }))
      .filter((s) => s.summaryTitle && s.summary);

    return (
      <>
        {summarySteps.map((step) => (
          <FormikSummaryDisplay
            key={step.summaryTitle}
            label={step.summaryTitle || ''}
            value={step.summary || ''}
            onClick={() => setCurrentStepPosition(step.index + 1)}
          />
        ))}
      </>
    );
  };

  return (
    <>
      <Heading as="h1" mb="space50" size="fontSize50">
        Review and submit your order.
      </Heading>
      {hasError && <AlertMessage />}
      <FormatSummary />
      <NavigationButton goNext={handleOpenOrderBFF} isLoading={isSubmitting}>
        Open Title & Escrow
      </NavigationButton>
    </>
  );
};

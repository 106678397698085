import React, { FC, useState, useContext, useEffect } from 'react';
import { Box, Button, Divider, Flex, Heading, Switch, useToast } from '@endpoint/blockparty';
import { useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';
import { RouteOptions, UnityRole } from 'Auth/Utilities/Constant';
import { AppContext, AppContextType } from 'utils/context';
import { trackAction, trackIdentityPreCreation } from 'utils/analytics';
import {
  ButtonClickedTrackingPayload,
  SignInTrackingEvents,
  SignUpTrackingEvents,
  TrackingEventType,
} from 'consts/analytics';
import { useUnityContact } from 'hooks/useUnityContact';
import {
  UnityContactWhereUniqueInput,
  NotificationPreferencesInput,
  UpdateUnityContactInput,
} from '@endpoint/endpoint-bff-graphql-schema';
import Toast from 'Auth/Components/Toast';
import { useGetActiveFileNumbers } from 'hooks/useGetActiveFileNumbers';
import { useGetParticipantRoles } from 'hooks/useGetParticipantRoles';
import { getUserRolesForSignupSegmentTracking, isUserBuyerOrSeller } from 'Auth/Utilities/helper';

import { isWebView } from '../../../utils/webview';
import { getUserFullName } from '../../../utils/getUserFullName';

export interface TrackActionSignUp {
  userId?: string;
  email?: string;
  name?: string;
  phoneNumber?: string;
  channel: string;
  ssoPartner?: string | null;
  isWebView?: boolean;
  referrer?: string;
  roles?: string[];
  rolesStr?: string;
  onboardingRole?: string;
  fileNumbers?: string[];
}

const NotificationPreferenceUnity: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const { authFields, user, setUser }: AppContextType = useContext(AppContext);
  const { sms, email: userEmailNotification } = user?.notificationPreferences || {};

  const [emailNotification, setEmailNotification] = useState<boolean>(userEmailNotification ?? true);
  const [smsNotification] = useState<boolean>(sms ?? false);
  const { updateUnityContact, updateUnityContactLoading, updateUnityContactResult, updateUnityContactError } =
    useUnityContact();
  const { getActiveFileNumbers, activeFileNumbers } = useGetActiveFileNumbers();
  const { getParticipantRoles, participantRoles } = useGetParticipantRoles();
  const CREATE_ACCOUNT_TEXT = 'Create Account';

  useEffect(() => {
    if (!user) {
      navigate(RouteOptions.SIGN_IN);
    }
  }, [navigate, user]);

  useEffect(() => {
    getActiveFileNumbers();
  }, [getActiveFileNumbers]);

  useEffect(() => {
    getParticipantRoles();
  }, [getParticipantRoles]);

  useEffect(() => {
    if (!updateUnityContactLoading && updateUnityContactResult) {
      const { id = '', email = '', phone = '' } = updateUnityContactResult.updateUnityContact;
      const signupRoles = getUserRolesForSignupSegmentTracking(user?.roles, participantRoles);

      const trackActionInfo: TrackActionSignUp = {
        userId: id,
        email,
        name: getUserFullName(updateUnityContactResult.updateUnityContact),
        phoneNumber: phone,
        roles: signupRoles,
        rolesStr: signupRoles.join(),
        onboardingRole: signupRoles.join(),
        channel: 'nonsso',
        isWebView: isWebView(),
        referrer: authFields.referrer,
      };
      const fileNumbers = activeFileNumbers?.getActiveFileNumbers;

      if (fileNumbers?.length && isUserBuyerOrSeller(participantRoles)) {
        trackActionInfo.fileNumbers = fileNumbers;
      }

      // React is complaining about running setState on an unmounted component without this setTimeout
      setTimeout(() => {
        setUser({
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          middleName: user?.middleName,
          isAgent: user?.unityRole === UnityRole.AGENT,
          isTransactionCoordinator: user?.unityRole === UnityRole.TRANSACTION_COORDINATOR,
          transactions: user?.transactions,
          isOnboarded: true,
          refetch: true,
          email: user.email,
          phone: user?.phone,
        });
      }, 0);

      trackAction(SignInTrackingEvents.ACCOUNT_CREATED, trackActionInfo);
    }

    if (!updateUnityContactLoading && updateUnityContactError) {
      toast({
        duration: 4000,
        position: 'top-right',
        render: ({ onClose, id }) => <Toast id={id} message={updateUnityContactError.message} onClose={onClose} />,
      });

      Sentry.captureEvent({
        level: Sentry.Severity.Error,
        message: `updateContact mutation error -- Set up profile screen / Onboarding. Contact id: ${
          user.id
        }. Error: ${JSON.stringify(updateUnityContactError)}}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUnityContactLoading, updateUnityContactResult, updateUnityContactError]);

  const handleEmailClick = () => {
    setEmailNotification(!emailNotification);
  };

  const handleSubmit = async () => {
    const unityContactWhereUniqueInput: UnityContactWhereUniqueInput = {
      id: user.id!,
    };
    const notificationPreferences: NotificationPreferencesInput = {
      email: emailNotification,
      sms: smsNotification,
    };
    const updateUnityContactInput: UpdateUnityContactInput = {
      notificationPreferences,
      isOnboarded: true,
    };
    const trackNotificationPreferenceSubmitProperties: ButtonClickedTrackingPayload = {
      name: TrackingEventType.BUTTON_CLICKED,
      buttonId: CREATE_ACCOUNT_TEXT,
    };

    trackAction(SignUpTrackingEvents.CREATE_ACCOUNT_SUBMITTED, trackNotificationPreferenceSubmitProperties);
    trackIdentityPreCreation({
      notificationPreference: {
        email: emailNotification,
        sms: smsNotification,
      },
    });

    await updateUnityContact(unityContactWhereUniqueInput, updateUnityContactInput);
  };

  return (
    <>
      <Heading as="h2" fontWeight="semi" mb="space50" size={{ base: 'fontSize60', md: 'fontSize70' }}>
        Notification preferences
      </Heading>
      <Box fontSize="fontSize20" lineHeight="lineHeight20" mb="space50">
        We will only send you important notifications that keep you fully up to speed on everything related to your
        transaction.
      </Box>
      <Box fontSize="fontSize20" lineHeight="lineHeight20" mb="space70">
        You can receive notifications via email and can always change your settings later.
      </Box>
      <Box mb="space80">
        <Divider my="space20" />
        <Flex justifyContent="space-between" mb="space50" mt="space50">
          <Box as="label" fontSize="fontSize20" htmlFor="email-notifications" lineHeight="lineHeight20">
            Enable email notifications
          </Box>
          <Switch
            data-test-id="email-notifications"
            id="email-notifications"
            isChecked={emailNotification}
            onChange={handleEmailClick}
          />
        </Flex>
        <Divider my="space20" />
      </Box>
      <Flex
        backgroundColor="white"
        bottom={{ base: 0, md: 'initial' }}
        boxShadow={{ base: 'medium', md: 'none' }}
        justifyContent="flex-end"
        left={{ base: 0, md: 'initial' }}
        position={{ base: 'fixed', md: 'initial' }}
        px={{ base: 'space50', md: 'space0' }}
        py={{ base: 'space30', md: 'space0' }}
        right={{ base: 0, md: 'initial' }}
        zIndex="overlay"
      >
        <Button data-test-id="submit" isLoading={updateUnityContactLoading} type="submit" onClick={handleSubmit}>
          {CREATE_ACCOUNT_TEXT}
        </Button>
      </Flex>
    </>
  );
};

export default NotificationPreferenceUnity;

/* eslint no-underscore-dangle: 0 */
/* eslint no-param-reassign: 0 */
/* eslint dot-notation: 0 */

/**
 * This file handles authentication and legacy APIs
 */

import axios, { AxiosError, Method } from 'axios';
import { getAccessTokenV2 } from 'utils/auth/storage';
import { renewToken } from 'utils/auth';
// fix typescript error
declare module 'axios' {
  interface AxiosInstance {
    (config: AxiosRequestConfig): Promise<any>;
  }
}

axios.interceptors.response.use(
  (response) => response.data,
  (error) => errorHandler(error),
);

interface Config {
  method?: string;
  data?: any;
  headers?: {
    [key: string]: unknown;
  };
  [key: string]: unknown;
}

export const fetchHelper = async (url: string, config?: Config, combineHeaders: boolean = true) => {
  const accessToken = await getAccessTokenV2();

  const { method = 'GET', headers, data, ...configs } = config || {};
  const Headers = combineHeaders ? { Authorization: `Bearer ${accessToken}`, ...headers } : headers;

  return axios({
    url,
    method: method as Method,
    headers: Headers,
    ...(data && method.toLowerCase() !== 'get' && { data }),
    ...(configs || {}),
  });
};

// ============================================================ //

const LegacyAuthErrors = [
  'invalid refresh token',
  'invalid token',
  'jwt expired',
  'secret or public key must be provided',
  'no authorization token was found',
  'invalid signature',
  'jwt malformed',
];

const isAuthError = (errorMessage: string) => {
  return LegacyAuthErrors.some((e) => e === errorMessage);
};

const replaceUnderscoresWithSpaces = (errorMessage: string = '') => {
  return errorMessage.replace(/[_]/g, ' ');
};

async function errorHandler(error: AxiosError) {
  const { config, response = {} } = error;
  // @ts-ignore
  const { data = {}, status } = response;
  const sanitizedErrorMessage = replaceUnderscoresWithSpaces(data.error);
  const errorMessage = sanitizedErrorMessage.toLowerCase();

  if (isAuthError(errorMessage) && status === 400) {
    const newToken = await renewToken();

    if (!newToken) {
      return Promise.reject(new Error(`${error.toJSON()}`));
    }

    // @ts-ignore
    if (config.__retryCount >= 3) {
      return Promise.reject(new Error(`${error.toJSON()}`));
    }

    // @ts-ignore
    config.__isRetryRequest = true;
    // @ts-ignore
    config.__retryCount = config.__retryCount || 0;
    // @ts-ignore
    config.__retryCount += 1;
    const headers = error.config?.headers || {};

    headers.Authorization = `Bearer ${newToken}`;
    axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;

    return axios.request(error.config!);
  }

  return Promise.reject(response); // preferably error.response.data.message
}

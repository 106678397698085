import { capitalize } from 'lodash';
import { useCallback, useContext, useState } from 'react';
import { AppContext, AppContextType, LastTransactionViewedType, User } from 'utils/context';
import { getSupportedUSState } from 'utils/getSupportedUSState';
import { MeResponse } from '@endpoint/endpoint-bff-graphql-schema';

export const useSetAppContextUser = () => {
  const { setUser }: AppContextType = useContext(AppContext);
  const [lastTransactionViewed, setLastTransactionViewed] = useState<LastTransactionViewedType>({
    fileNum: '',
    marketId: undefined,
    officeName: '',
  });

  const setAppContextUser = useCallback(
    (getMeData: MeResponse) => {
      const userStateFromGetMe: User = {
        loading: !getMeData,
        id: getMeData?.id,
        firstName: getMeData?.firstName && capitalize(getMeData?.firstName),
        middleName: getMeData?.middleName && capitalize(getMeData?.middleName),
        lastName: getMeData?.lastName && capitalize(getMeData?.lastName),
        email: getMeData?.email,
        phone: getMeData?.phone,
        contactType: getMeData?.contactType,
        suffix: getMeData?.suffix,
        entityName: getMeData?.entityName,
        realEstateAgentLicenseNumber: getMeData?.licenseNumber,
        notificationPreferences: getMeData?.notificationPreferences,
        isOnboarded: getMeData?.isOnboarded ?? false,
        group: getMeData?.group,
        refetch: false,
        lastTransactionViewed,
        updateLastTransactionViewed: setLastTransactionViewed,
        stateSignedUp: getSupportedUSState(getMeData?.stateOfOperation),
        transactions: getMeData?.transactions || [],
        isAgent: getMeData.isAgent,
        isTransactionCoordinator: getMeData.isTransactionCoordinator,
      };

      setUser((currentUserState: User) => ({
        ...currentUserState,
        ...userStateFromGetMe,
      }));
    },
    [lastTransactionViewed, setUser],
  );

  return {
    setAppContextUser,
  };
};
